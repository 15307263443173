import './main.scss';
import './js/plugins/owl.carousel.js';

$(document).ready(function(){    
    $('a[href*="#"]:not([href="#"])').click(function() {
      if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
        if (target.length) {
          $('html, body').animate({
            scrollTop: ((target.offset().top) - 140)
          }, 1000);
          $("#navbarTogglerMenu").collapse('hide');
          return false;
        }
      }
    });
    /* ------------------ OWL CAROUSEL ------------------ */

    $(".carousel").each(function () {
        var $Carousel = $(this);
        $Carousel.owlCarousel({
            loop: $Carousel.data('loop'),
            autoplay: $Carousel.data("autoplay"),
            margin: $Carousel.data('space'),
            nav: $Carousel.data('nav'),
            dots: $Carousel.data('dots'),
            center: $Carousel.data('center'),
            dotsSpeed: $Carousel.data('speed'),
            responsive: {
                0: {
                    items: 1,
                },
                600: {
                    items: $Carousel.data('slide-rs'),
                },
                1000: {
                    items: $Carousel.data('slide'),
                }
            }
        });
        console.log("oi");
    });


/*
  $('#form-contato').submit(function () {
    console.log($(this).serialize());
    var request = $.ajax({
      url: "https://api.sandbox.seupredio.com/contato",
      method: "POST",
      data: $(this).serialize(),
      dataType: "json"
    });
    $('#form-contato input[name="btnEnviar"]').val('Enviando...');
    $('#form-contato input[name="btnEnviar"]').prop('disabled', true);
    request.done(function (msg) {

      if (msg.erro == 0) {
        Swal.fire('Obrigado!', msg.msg, 'success');
        $('#form-contato').find('input').each(function () {
          $(this).val('');
        });
        $('#form-contato').find('textarea').each(function () {
          $(this).val('');
        });
      } else {
        Swal.fire('Ops!', msg.msg, 'error');
      }

      $('#form-contato input[name="btnEnviar"]').val('Enviar Mensagem');
      $('#form-contato input[name="btnEnviar"]').prop('disabled', false);
    });
    request.fail(function (jqXHR, textStatus) {
      console.log(jqXHR)
      console.log(textStatus)
      Swal.fire('Ops!', 'Erro ao tentar enviar e-mail, verifique os campos', 'error');
      $('#form-contato input[name="btnEnviar"]').val('Enviar Mensagem');
      $('#form-contato input[name="btnEnviar"]').prop('disabled', false);
    });
    return false;
  });*/


  });